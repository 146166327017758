import * as React from 'react';
import { Wrapper } from 'src/containers';
import { Column } from 'src/components-v2/Layout';
import { RichTextSwitch } from 'src/containers/RichTextSwitch';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { isProduction } from 'src/lib/constants';
import { Sys } from 'contentful';
import { SxProps } from '@mui/system';

interface ICmsSectionalHeader {
  fields: any;
  sys: Sys;
  sx?: SxProps;
}

// TODO: curious why we aren't using sectional header here?
const CmsSectionalHeader = React.forwardRef(
  ({ fields, sys, sx = {} }: ICmsSectionalHeader, ref) => {
    const inspectorProps = useContentfulInspectorMode({
      entryId: sys?.id,
    });
    const bgColor = fields?.backgroundColor?.toLowerCase() || 'white';
    return (
      <Wrapper
        ref={ref}
        component='header'
        sx={{
          backgroundColor: bgColor || 'white',
          // ...sx,
          ...fields?.styles,
        }}
        {...inspectorProps({ fieldId: 'internalTitle' })}
      >
        <HeaderSwitch fields={fields} sys={sys} />
      </Wrapper>
    );
  },
);

export default CmsSectionalHeader;

const HeaderSwitch = ({ fields, sys }) => {
  switch (fields?.appearance) {
    case 'Centered':
      return (
        <Column
          xs={20}
          xsOffset={2}
          sm={18}
          smOffset={3}
          lg={16}
          lgOffset={4}
          sx={{
            textAlign: 'center',
            '& .eyebrow-heading': {
              mb: { xs: '1.8rem', md: '3.2rem' },
            },
            '& .decorative-text': {
              mb: { xs: '1.8rem', md: '3.2rem' },
            },
            '& h1:has(+ p:not(:empty))': {
              mb: { xs: '1.8rem', sm: '2.0rem', md: '3.2rem' },
            },
            '& h2:has(+ p:not(:empty))': {
              mb: { xs: '1.8rem', sm: '2.0rem', md: '3.2rem' },
            },
            '& p': {
              mb: '0.8rem',
            },
            '& p:nth-last-of-type(1):empty': {
              display: 'none',
            },
          }}
        >
          <RichTextSwitch
            sys={sys}
            content={fields?.leftCenteredBody}
            nodes={{
              h1: {
                variant: 'h1',
                fieldId: 'leftCenteredBody',
              },
              h2: {
                variant: 'h2',
                fieldId: 'leftCenteredBody',
              },
              h6: {
                variant: 'h6',
                component: 'h3',
                className: 'eyebrow-heading',
                fieldId: 'leftCenteredBody',
              },
              p: {
                variant: 'p',
                fieldId: 'leftCenteredBody',
              },
            }}
          />
        </Column>
      );
    case 'Left':
      return (
        <Column
          xs={22}
          xsOffset={1}
          lg={20}
          lgOffset={2}
          sx={{
            textAlign: 'left',
            '& .eyebrow-heading': {
              mb: { xs: '1.8rem', md: '3.2rem' },
            },
            '& .decorative-text': {
              mb: { xs: '1.8rem', md: '3.2rem' },
            },
            '& h1:has(+ p:not(:empty))': {
              mb: { xs: '1.8rem', sm: '2.0rem', md: '3.2rem' },
            },
            '& h2:has(+ p:not(:empty))': {
              mb: { xs: '1.8rem', sm: '2.0rem', md: '3.2rem' },
            },
            '& p': {
              mb: '0.8rem',
            },
            '& p:nth-last-of-type(1):empty': {
              display: 'none',
            },
          }}
        >
          <RichTextSwitch
            sys={sys}
            content={fields?.leftCenteredBody}
            nodes={{
              h1: {
                variant: 'h1',
                component: 'h1',
                fieldId: 'leftCenteredBody',
              },
              h2: {
                variant: 'h1',
                component: 'h2',
                fieldId: 'leftCenteredBody',
              },
              h6: {
                variant: 'h6',
                component: 'h3',
                className: 'eyebrow-heading',
                fieldId: 'leftCenteredBody',
              },
              p: {
                variant: 'p',
                fieldId: 'leftCenteredBody',
              },
            }}
          />
        </Column>
      );
    case 'With pills':
      return (
        <Column xs={22} xsOffset={1} lg={20} lgOffset={2}>
          <RichTextSwitch
            sys={sys}
            content={fields?.pillBody}
            nodes={{
              h1: {
                variant: 'h1',
                fieldId: 'pillBody',
              },
              h2: {
                variant: 'h2',
                fieldId: 'pillBody',
                sx: {
                  lineHeight: { xs: '5.2rem', sm: '4.8rem', md: '9rem' },
                },
              },
            }}
          />
        </Column>
      );
    case 'Outlined':
      return (
        <Column xs={24} xsOffset={0} lg={20} lgOffset={2}>
          <RichTextSwitch
            sys={sys}
            content={fields?.leftCenteredBody || fields?.pillBody}
            nodes={{
              h1: {
                variant: 'h2',
                fieldId: 'pillBody',
              },
              h2: {
                component: 'h2',
                variant: 'h3',
                fieldId: 'outlined',
                sx: {},
              },
            }}
          />
        </Column>
      );
    default:
      if (!isProduction) {
        return <p>hmm something went wrong</p>;
      }
      return null;
  }
};
