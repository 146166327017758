import * as React from 'react';
import { Column } from 'src/components-v2/Layout';
import { IPaginatedCard } from '../Paginator';

interface IImageCardsProps {
  cards: Array<IPaginatedCard> | Array<React.ReactElement<unknown>>;
  cardRef?: React.Ref<HTMLDivElement>;
}

const ImageCards: React.FunctionComponent<IImageCardsProps> = ({
  cards,
  cardRef,
}) => {
  return (
    <>
      {cards?.map((card, i) => {
        const columns = getColumn(cards, i);
        return (
          <Column
            ref={cardRef}
            key={`card-column-${i}`}
            sx={{
              minWidth: {
                xs: 'calc(100% * 21 / var(--Grid-columns))',
                sm: '0',
              },
              '& a': {
                mt: {
                  xs: '1.6rem',
                  sm: '0.4rem',
                  lg: '2.4rem',
                },
              },
              ...card.sx,
            }}
            {...columns}
          >
            {/* TODO: remove this when we remove the static payment and fraud-compliance pages: https://plaidinc.atlassian.net/browse/SITE-5099 */}
            {!card?.content && card}
            {card?.content && card?.content}
          </Column>
        );
      }) || null}
    </>
  );
};

export default ImageCards;

export const getColumn = (items: Array<unknown>, index: number) => {
  if (items.length === 3) {
    return {
      lgOffset: index === 0 ? 2 : 1,
      lg: 6,
      sm: 8,
      smOffset: 0,
      xs: 21,
      xsOffset: 1,
    };
  }
  return {
    // two columns
    lgOffset: 2,
    lg: 9,
    sm: 10,
    smOffset: index === 0 ? 1 : 2,
    xs: 21,
    xsOffset: 1,
  };
};
